<template>
  <dialogComp
    class="after_sales_add"
    top="9vh"
    :title="dialogTitle"
    width="640px"
    :isShow="isShow"
    :loading="loading"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="115px"
    >
      <div class="flex" style="margin-bottom:10px">
        <div style="margin-right:20px">订单ID：{{ formData.orderCode }}</div>
        <div>商家：{{ formData.storeName || '-' }}</div>
      </div>
      <div class="flex">
        <div style="margin-right:20px;width:260px">
          设备类目：{{ formData.categoryName }}
        </div>
        <div style="margin-bottom:10px">
          商品名称：{{ formData.commodityName }}
        </div>
      </div>
      <div class="flex">
        <inputFormComp
          label="报修联系人:"
          prop="repairContacter"
          :hval="formData.repairContacter"
          @blur="repairContacterBlur"
          placeholder="请输入报修联系人"
        />
        <inputFormComp
          label="联系电话:"
          prop="contacterMobile"
          :hval="formData.contacterMobile"
          @blur="contacterMobileBlur"
          placeholder="请输入联系电话"
        />
      </div>
      <div class="flex">
        <el-form-item label="使用地址:" prop="area">
          <areaData :hval="formData.area" @change="areaChange" />
        </el-form-item>
        <el-form-item label="" prop="addressChecked" class="common">
          <el-checkbox v-model="formData.addressChecked"
            >已确认地址</el-checkbox
          >
        </el-form-item>
        <el-form-item label="" prop="premiumProcessing" class="common">
          <el-checkbox
            :true-label="1"
            :false-label="0"
            v-model="formData.premiumProcessing"
            >加急</el-checkbox
          >
        </el-form-item>
        <el-form-item label="" prop="second" class="common">
          <el-checkbox v-model="second" :disabled="isSecondScene"
            >二次售后</el-checkbox
          >
        </el-form-item>
      </div>
      <inputFormComp
        class="detailAddress"
        label=""
        prop="detailAddress"
        :hval="formData.detailAddress"
        @blur="detailAddressBlur"
        placeholder="请输入详细地址"
      />
      <div class="flex">
        <selectFormComp
          label="工单来源:"
          prop="source"
          :data="sourceList"
          :hval="formData.source"
          @change="sourceChange"
          placeholder="请选择来源"
        />
        <inputFormComp
          class="common"
          label=""
          prop="sourceCode"
          :hval="formData.sourceCode"
          @blur="sourceCodeBlur"
          placeholder="请输入来源关联工单号"
        />
      </div>
      <div class="flex">
        <selectFormComp
          label="业务类型:"
          prop="orderType"
          :data="orderTypeList"
          :hval="formData.orderType"
          @change="orderTypeChange"
          placeholder="请选择业务类型"
        />
        <selectFormComp
          label="工单类型:"
          prop="orderTypeChi"
          :data="orderTypeChiList"
          :hval="formData.orderTypeChi"
          @change="orderTypeChiChange"
          placeholder="请选择工单类型"
        />
      </div>
      <radioFormComp
        class="status"
        label="下步处理:"
        prop="status"
        :radioList="nextHandleList"
        :hval="formData.status"
        @change="statusChange"
      />
      <!-- 暂时隐藏不需要 -->
      <!-- <el-form-item label="转交人员:" prop="userId">
        <handleUser placeholder="请选择转交人员" :hval="userId" :businessRole="businessRole" @change="userIdChange" />
      </el-form-item> -->
      <div class="flex">
        <!-- <inputFormComp
          inputType="number"
          label="故障设备数量:"
          prop="amount"
          :hval="formData.amount"
          @blur="amountBlur"
          placeholder="请输入故障设备数量"
        /> -->
      </div>
      <div class="fault_box">
        <div
          class="flex fault"
          v-for="(item, index) in formData.items"
          :key="index"
        >
          <serialNumber
            label="故障现象:"
            class="first"
            prop="serialNum"
            :data="serialNumList"
            :hval="item.serialNum"
            @change="serialNumChange(index, $event)"
            placeholder="可选择可输入序列号"
          />
          <span class="xing">*</span>
          <cascader
            :data="deviceFaultList"
            :propsParams="{
              expandTrigger: 'click',
              value: 'id',
              label: 'appearance'
            }"
            :hval="filterArrNull([item.configFaultId, item.configFaultChildId])"
            @change="configFaultChange(index, $event)"
            placeholder="请选择故障现象"
            style="margin-bottom:10px"
          />
          <p style="width:40px">
            <i
              class="btn add el-icon-circle-plus-outline"
              v-show="index === formData.items.length - 1"
              @click="add"
            ></i>
            <i
              class="btn del el-icon-remove-outline"
              v-show="index !== 0"
              @click="del(index)"
            ></i>
          </p>
        </div>
      </div>
      <!-- 新增售后部需要下拉    二次售后部不需要 -->
      <selectFormComp
        v-if="second && !isSecondScene"
        prop="firstOrderId"
        label="上次售后单号:"
        :data="firstOrderIdList"
        placeholder="请选择上次售后单号"
        :hval="formData.firstOrderId"
        @change="firstOrderIdChange"
      />
       <inputFormComp
        v-if="isSecondScene"
        disabled
        label="上次售后单号:"
        :hval="code"
      />
      <paramsDict
        v-if="second"
        prop="reasonCode"
        label="二次上门原因:"
        :parentCode="101384"
        :hval="formData.reasonCode"
        placeholder="请选择二次上门原因"
        @change="reasonChange"
      />
      <inputFormComp
        :style="{ marginTop: second ? '20px' : '' }"
        label="备注:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import areaData from '@/views/components/areaDataNew'
import serialNumber from '@/views/components/serialNumber'
import paramsDict from '@/views/components/paramsDict'
// import handleUser from '@/views/components/handleUser'
import {
  serialByOrderId,
  deviceFaulThree,
  orderRepairs,
  getOrderTypeBySkuId,
  orderInfo,
  getInfoToSecondScene,
  serviceVisitAboutOrder,
  devicesInfoList,
  validateSerialNum
} from '@/api'
import {
  nextHandleList,
  sourceList,
  orderTypeList,
  orderTypeChiList1,
  orderTypeChiList2,
  orderTypeChiList3
} from '@/utils/constData'
import { handleArea, formatDate } from '@/utils/common'
export default {
  components: {
    areaData,
    serialNumber,
    paramsDict
    // handleUser
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    },
    // 告警事件信息
    eventInfo: {
      type: Object,
      default: () => {}
    },
    // dialogType是eventSend  表示是告警事件的派单弹窗
    // dialogType是secondScene  表示是二次上门弹窗
    dialogType: {
      type: String,
      default: ''
    },
    // 上门处理-二次上门orderId
    secondScenceOrderId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      second: false, // 二次售后
      loading: false,
      serialNumList: [],
      deviceFaultList: [],
      firstOrderIdList: [],
      orderTypeChiList: [],
      /* businessRole: 'aftermarket', // 根据不同的角色显示不同的转交人员 默认售后
      userId: '', // 选择的转派人的id */
      code: '', // 显示的工单号
      formData: {
        orderCode: '',
        commodityId: '',
        commodityName: '',
        rentStartDate: '',
        deliverGoodsDate: '',
        arriveGoodsDate: '',
        repairContacter: '',
        contacterMobile: '',
        area: [],
        province: '',
        city: '',
        region: '',
        detailAddress: '',
        addressChecked: false,
        source: '',
        sourceCode: '',
        status: 20,
        amount: '',
        premiumProcessing: 0,
        remark: '',
        storeName: '',
        categoryName: '',
        categoryId: '',
        firstOrderId: '',
        customerName: '',
        orderAuthName: '',
        orderAuthPhone: '',
        userPhone: '',
        productType: '',
        labelName: '',
        reasonCode: '',
        reasonContent: '',
        orderType: '',
        orderTypeChi: '',
        items: [
          {
            serialNum: '',
            configFaultId: '',
            configFaultChildId: ''
          }
        ]
      },
      rules: {
        repairContacter: [
          { required: true, message: '请输入报修联系人', trigger: 'blur' }
        ],
        contacterMobile: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        area: [{ required: true, message: '请选择区域', trigger: 'change' }],
        addressChecked: [
          { required: true, message: '请确认地址', trigger: 'change' }
        ],
        source: [{ required: true, message: '请选择来源', trigger: 'change' }],
        status: [
          { required: true, message: '请选择下步处理', trigger: 'change' }
        ],
        amount: [
          { required: true, message: '请输入故障设备数量', trigger: 'blur' }
        ],
        detailAddress: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        premiumProcessing: [
          { required: true, message: '请确认是否加急', trigger: 'change' }
        ],
        firstOrderId: [
          { required: true, message: '请选择上次售后单号', trigger: 'change' }
        ],
        reasonCode: [
          { required: true, message: '请选择二次上门原因', trigger: 'change' }
        ],
        orderType: [
          { required: true, message: '请选择业务类型', trigger: 'change' }
        ],
        orderTypeChi: [
          { required: true, message: '请选择工单类型', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    nextHandleList () {
      return nextHandleList
    },
    orderTypeList () {
      return orderTypeList
    },
    orderTypeChiList1 () {
      return orderTypeChiList1
    },
    orderTypeChiList2 () {
      return orderTypeChiList2
    },
    orderTypeChiList3 () {
      return orderTypeChiList3
    },
    sourceList () {
      return sourceList
    },
    // 是否是二次上门
    isSecondScene () {
      return this.dialogType === 'secondScene'
    },
    // dialogTitle
    dialogTitle () {
      let title = ''
      switch (this.dialogType) {
        case 'secondScene':
          title = '二次售后'
          break
        case 'eventSend':
          title = '派单'
          break
        default:
          title = '新增售后'
          break
      }
      return title
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        if (this.dialogType === 'secondScene') {
          this.second = this.isSecondScene
          this.getSecondSceneInfo()
          // 上门处理的二次上门
        } else {
          // 告警事件和报修
          const {
            brandId,
            brandName,
            categoryId,
            categoryName,
            userId,
            orderId,
            goodsSn,
            goodsName,
            leaseBeginDate,
            shippingTime,
            arrivalTime,
            companyName,
            companyId,
            customerName,
            orderAuthName,
            orderAuthPhone,
            userPhone,
            productType,
            labelName
          } = this.info
          this.getOrderType(goodsSn)
          this.formData.brandId = brandId
          this.formData.brandName = brandName
          this.formData.categoryId = categoryId
          this.formData.categoryName = categoryName
          this.formData.commodityId = goodsSn
          this.formData.commodityName = goodsName
          this.formData.customerName = customerName
          this.formData.customerId = userId
          this.formData.storeName = companyName
          this.formData.storeId = companyId
          this.formData.orderCode = orderId
          this.formData.placeContacter = orderAuthName
          this.formData.placeContacterMobile = orderAuthPhone
          this.formData.registrantContacterMobile = userPhone
          this.formData.commodityType = productType === '设备' ? 1 : 2
          this.formData.customerLabel = labelName
          this.formData.rentStartDate = leaseBeginDate
            ? formatDate(leaseBeginDate, 'YYYY-MM-DD HH:mm:ss')
            : ''
          this.formData.deliverGoodsDate = shippingTime
            ? formatDate(shippingTime, 'YYYY-MM-DD HH:mm:ss')
            : ''
          this.formData.arriveGoodsDate = arrivalTime
            ? formatDate(arrivalTime, 'YYYY-MM-DD HH:mm:ss')
            : ''
          this.getOrderInfo(orderId)
          this.getDeviceFaultList()
          this.getSerialNum(orderId)
          this.getAboutOrder()
        }
      }
    }
  },
  methods: {
    // 通过商品编号获取业务类型
    getOrderType (skuId) {
      getOrderTypeBySkuId({ skuId }).then(res => {
        // 后端没有数据的话  默认就是1
        this.formData.orderType = res.data ? res.data.orderType : 1
        this.orderTypeChiList = this[
          'orderTypeChiList' + this.formData.orderType
        ]
        // 工单类型默认选中第一项
        this.formData.orderTypeChi = this.orderTypeChiList[0].value
      })
    },
    // 获取售后单号
    getAboutOrder () {
      serviceVisitAboutOrder({ orderCode: this.formData.orderCode }).then(
        res => {
          this.firstOrderIdList = res.data.map(item => ({
            value: item.orderId,
            label: item.code
          }))
        }
      )
    },
    // 封装方法，删掉数组中的null的数据
    filterArrNull (arr) {
      return arr.filter(item => item !== null && item !== '0' && item !== '')
    },
    // 获取设备序列号和故障现象
    getDevicesInfoList (orderId) {
      const params = {
        curPage: 1,
        pageSize: 100,
        orderId
      }
      devicesInfoList(params).then(res => {
        this.formData.items = res.data.list.map(item => ({
          serialNum: item.serialNum,
          configFaultId: item.configFaultId,
          configFaultChildId: item.configFaultChildId
        }))
      })
    },
    // 通过上门处理-点击二次上门的orderId来获取信息
    async getSecondSceneInfo () {
      const res = await getInfoToSecondScene({ id: this.secondScenceOrderId })
      const {
        id,
        code,
        orderCode,
        commodityId,
        commodityName,
        rentStartDate,
        deliverGoodsDate,
        arriveGoodsDate,
        repairContacter,
        contacterMobile,
        province,
        city,
        region,
        detailAddress,
        addressChecked,
        source,
        sourceCode,
        amount,
        premiumProcessing,
        storeName,
        storeId,
        categoryName,
        categoryId,
        customerLabel,
        commodityType,
        registrantContacterMobile,
        placeContacterMobile,
        placeContacter,
        customerId,
        customerName,
        brandName,
        orderType,
        orderTypeChi,
        itemUrl,
        repairCode
      } = res.data
      this.formData = {
        orderCode,
        commodityId,
        commodityName,
        rentStartDate,
        deliverGoodsDate,
        arriveGoodsDate,
        repairContacter,
        contacterMobile,
        province,
        city,
        region,
        detailAddress,
        addressChecked,
        source,
        sourceCode,
        status: 20,
        amount,
        premiumProcessing,
        remark: '',
        storeName,
        storeId,
        categoryName,
        categoryId,
        customerLabel,
        commodityType,
        registrantContacterMobile,
        placeContacterMobile,
        placeContacter,
        customerId,
        customerName,
        brandName,
        orderType,
        orderTypeChi,
        firstOrderId: '',
        reasonCode: '',
        reasonContent: '',
        repairCode: '',
        items: [
          {
            serialNum: '',
            configFaultId: '',
            configFaultChildId: ''
          }
        ]
      }
      if (this.isSecondScene) {
        // 二次售后
        this.itemUrl = itemUrl
        this.code = code
        this.formData.firstOrderId = id
        this.formData.repairCode = repairCode
      } else {
        // 新增售后
        this.formData.firstOrderId = ''
      }
      this.formData.area = handleArea(province, city, region)
      this.getDeviceFaultList()
      this.getSerialNum(orderCode)
      this.getAboutOrder()
      this.getOrderType(commodityId)
      this.getDevicesInfoList(id)
    },
    // 获取序列号下拉
    getSerialNum (orderId) {
      serialByOrderId({ orderId }).then(res => {
        if (res.code === 0) {
          this.serialNumList = Array.isArray(res.result) ? res.result : []
        }
      })
    },
    // 获取地址、联系人、电话信息
    getOrderInfo (orderId) {
      orderInfo({ orderId }).then(res => {
        if (res.code === 0) {
          const {
            provinceCode,
            cityCode,
            districtCode,
            areaDetail,
            receiver,
            phone,
            address
          } = res.result
          this.formData.contacterMobile = phone
          this.formData.repairContacter = receiver
          if (provinceCode && cityCode && districtCode) {
            this.formData.province = provinceCode
            this.formData.city = cityCode
            this.formData.region = districtCode
            this.formData.detailAddress = areaDetail
            this.formData.area = handleArea(
              +provinceCode,
              +cityCode,
              +districtCode
            )
          } else {
            this.formData.detailAddress = address + areaDetail
          }
        }
      })
    },
    // 获取故障现象下拉
    getDeviceFaultList () {
      const params = {
        categoryId: this.formData.categoryId
      }
      deviceFaulThree(params).then(res => {
        this.deviceFaultList = res.data
      })
    },
    repairContacterBlur (val) {
      this.formData.repairContacter = val
    },
    contacterMobileBlur (val) {
      this.formData.contacterMobile = val
    },
    areaChange (val) {
      this.formData.area = val
      const [a, b, c] = val
      this.formData.province = a || ''
      this.formData.city = b || ''
      this.formData.region = c || ''
    },
    detailAddressBlur (val) {
      this.formData.detailAddress = val
    },
    sourceChange (val) {
      this.formData.source = val
    },
    sourceCodeBlur (val) {
      this.formData.sourceCode = val
    },
    orderTypeChange (val) {
      this.formData.orderType = val
      if (val) {
        this.orderTypeChiList = this['orderTypeChiList' + val]
        this.formData.orderTypeChi = this.orderTypeChiList[0].value
      } else {
        this.orderTypeChiList = []
        this.formData.orderTypeChi = ''
      }
    },
    orderTypeChiChange (val) {
      this.formData.orderTypeChi = val
    },
    amountBlur (val) {
      this.formData.amount = val
    },
    statusChange (val) {
      this.formData.status = val
      /* this.userId = ''
      switch (val) {
        case 10:
          this.businessRole = 'service'
          break
        case 20:
          this.businessRole = 'aftermarket'
          break
        case 30:
          this.businessRole = 'store'
          break
        case 40:
          this.businessRole = 'dispatch'
          break
      } */
    },
    /*  userIdChange (val) {
      this.userId = val
    }, */
    serialNumChange (index, val) {
      val = val.trim()
      this.formData.items[index].serialNum = val
      validateSerialNum({ code: val }).then(res => {
        if (res.data) {
          this.formData.items[index].serialNum = val
        } else {
          this.formData.items[index].serialNum = ''
          this.$message.error(`该序列号${val}存在未完成的工单`)
        }
      })
    },
    configFaultChange (index, val) {
      switch (val.length) {
        case 0:
          this.formData.items[index].configFaultId = ''
          this.formData.items[index].configFaultChildId = ''
          break
        case 1:
          this.formData.items[index].configFaultId = val[0]
          this.formData.items[index].configFaultChildId = ''
          break
        case 2:
          this.formData.items[index].configFaultId = val[0]
          this.formData.items[index].configFaultChildId = val[1]
          break
      }
    },
    // 上次售后单号
    firstOrderIdChange (val) {
      this.formData.firstOrderId = val
    },
    reasonChange (val) {
      this.formData.reasonContent = val.name
      this.formData.reasonCode = val.code
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    add () {
      this.formData.items.push({
        serialNum: '',
        configFaultId: '',
        configFaultChildId: ''
      })
    },
    del (index) {
      this.formData.items.splice(index, 1)
    },
    close () {
      this.$refs.formData.resetFields()
      /* this.userId = ''
      this.businessRole = '' */
      this.formData = {
        orderCode: '',
        commodityId: '',
        commodityName: '',
        rentStartDate: '',
        deliverGoodsDate: '',
        arriveGoodsDate: '',
        repairContacter: '',
        contacterMobile: '',
        area: [],
        province: '',
        city: '',
        region: '',
        detailAddress: '',
        addressChecked: false,
        source: '',
        sourceCode: '',
        status: 20,
        amount: '',
        premiumProcessing: 0,
        remark: '',
        storeName: '',
        categoryName: '',
        categoryId: '',
        firstOrderId: '',
        customerName: '',
        orderAuthName: '',
        orderAuthPhone: '',
        userPhone: '',
        productType: '',
        labelName: '',
        reasonCode: '',
        reasonContent: '',
        orderType: '',
        orderTypeChi: '',
        items: [
          {
            serialNum: '',
            configFaultId: '',
            configFaultChildId: ''
          }
        ]
      }
      this.second = false
      this.orderTypeChiList = []
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { items, reasonCode, reasonContent } = this.formData
          if (items.some(item => item.configFaultId === '')) {
            this.$message.error('故障现象不能为空')
            return
          }
          // 弹窗类型是告警事件中的派单操作  需要加上报障事件id和报障单来源字段
          if (this.dialogType === 'eventSend') {
            this.formData.faultIncidentId = this.eventInfo.id
            // 报障单来源，1：租葛企服订单（默认），2：报障事件
            this.formData.orderSource = 2
          }
          /*  // 转那个部门就增加对应的人员id
          if (this.userId) {
            switch (this.formData.status) {
              case 10:
                this.formData.serviceId = this.userId
                this.formData.remoteId = ''
                this.formData.storeHandleId = ''
                this.formData.dispatchId = ''
                break
              case 20:
                this.formData.serviceId = ''
                this.formData.remoteId = this.userId
                this.formData.storeHandleId = ''
                this.formData.dispatchId = ''
                break
              case 30:
                this.formData.serviceId = ''
                this.formData.remoteId = ''
                this.formData.storeHandleId = this.userId
                this.formData.dispatchId = ''
                break
              case 40:
                this.formData.serviceId = ''
                this.formData.remoteId = ''
                this.formData.storeHandleId = ''
                this.formData.dispatchId = this.userId
                break
            }
          } */
          const cloneFormData = JSON.parse(JSON.stringify(this.formData))
          delete cloneFormData.items
          delete cloneFormData.area
          const params = {
            items,
            order: cloneFormData,
            reasonCode,
            reasonContent,
            itemUrl: this.dialogType === 'secondScene' ? this.itemUrl : (this.info && this.info.goodsMainPic)
          }
          this.loading = true
          orderRepairs(params).then(res => {
            this.loading = false
            this.$message.success('新增成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.after_sales_add {
  ::v-deep .el-form-item {
    margin-bottom: 10px;
    &.base_info {
      margin-bottom: 0;
      line-height: 30px;
      .el-form-item__label,
      .el-form-item__content {
        line-height: 30px;
      }
      &.companyName {
        position: relative;
        left: -41px;
      }
    }
  }
  ::v-deep .status {
    .el-radio-group {
      display: flex;
      align-items: center;
      height: 40px;
      .el-radio {
        margin-right: 27px;
      }
    }
  }
  .xing {
    color: #f56c6c;
    position: relative;
    top: -8px;
    left: 2px;
    width: 10px;
  }
  .fault_box {
    max-height: 200px;
    padding-right: 5px;
    overflow-y: scroll;
    overflow-x:hidden;
  }
}
::v-deep .common {
  flex: 1;
  .el-form-item__content {
    margin-left: 10px !important;
  }
}
::v-deep .fault {
  align-items: center;
  .btn {
    position: relative;
    top: -2px;
    left: 5px;
    width: 20px;
    font-size: 20px;
    cursor: pointer;
  }
  > div {
    flex: 2;
    .el-form-item__content {
      margin-left: 10px !important;
    }
    &.first {
      flex: 3;
      .el-form-item__content {
        margin-left: 115px !important;
      }
    }
  }
}
</style>
